<template>
  <v-expansion-panels
    v-if="artistGiclees.length"
    accordion
    v-model="panel"
  >
    <v-expansion-panel v-for="(artwork, index) of artistGiclees" :key="index">
      <v-expansion-panel-header>
        <div class="d-flex justify-space-between align-center">
          <h4>{{ index }} ({{ artwork.total }})</h4>
          <v-btn
            class="ma-2"
            color="primary"
            @click.stop="downloadExcel(index)"
          >
            <v-icon>mdi-table</v-icon>
          </v-btn>
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-data-table
          :headers="headers"
          :items="artist.products"
          :items-per-page="5"
          class="elevation-1"
        >
          <template v-slot:[`item.image`]="{ item }">
            <v-img
              class="my-2"
              max-height="50px"
              max-width="50px"
              :src="`${imageUrl}/100px/${getImageToDisplay(item.image)}`"
            ></v-img>
          </template>
        </v-data-table>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import ArtistApi from "@/api/admin/artist";
import ArtworkApi from "@/api/artwork";
import ProductApi from "@/api/admin/product"

export default {
  props: {
    artistCode: String
  },
  data() {
    return {
      type: null,
      artistGiclees
    }
  },
  async created() {
    ArtistApi.loadArtistGiclees({ artistCodes: [artistCode] }).then((res) => {
      this.artistGiclees = res.data;
    });
  },
  computed: {
    filter() {
    },
  },
  methods: {
    async setType(evt) {
      this.giclees = []
      switch (evt) {
        case 'region' :
          this.selectedArtist = null
          this.giclees = this.regionalGiclees
        break
        case 'artist' :
          this.selectedRegion = null
          this.giclees = this.artistGiclees
        break
      }
    },
  }
};
</script>
